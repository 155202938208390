import React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, Fade,
    FormControlLabel, IconButton, InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import {login} from "../store/slices/authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from "dayjs";
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "./CustomToolbar";
import {create, deleteTokens, edToken, getTokens} from "../store/slices/tokenSlice";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import localeGrid from "./localeGrid";

function Tokens() {

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75
        },
        {
            field: 'name',
            headerName: 'Название',
            width: 150
        },
        {
            field: 'is_activate',
            headerName: 'Активен?',
            renderCell: (cellValues) => {
                return (
                    <Checkbox
                        disabled
                        checked={cellValues.value == 1}
                    >
                    </Checkbox>
                );
            },
            width: 100
        },
        {
            field: 'created_at',
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format('DD.MM.YYYY HH:mm')
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: 'Действия',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Удалить"
                    onClick={deleteToken(params.id)}
                />,
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Редактировать"
                    onClick={editToken(params.row)}
                />,
                <GridActionsCellItem
                    icon={<VisibilityIcon/>}
                    label="Показать ключи"
                    onClick={showToken(params.row)}
                />
            ]
        }

    ];
    const [tableHeight, setTableHeight] = React.useState(300);
    const dispatch = useDispatch();
    const tokens = useSelector((state) => state.token.tokens)
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [deletedTokens, setDeletedTokens] = React.useState([]);
    const [openShowDialog, setOpenShowDialog] = React.useState(false);

    // const resize = async () => {
    //     console.log(window.innerHeight)
    //     setTableHeight(window.innerHeight - 200)
    // }

    React.useEffect(() => {
        dispatch(getTokens())
    }, [])

    React.useEffect(() => {
        // dispatch(getUsers())
        // resize()
        // window.addEventListener('resize', resize)
        // return () => {
        //     window.removeEventListener('resize', resize);
        // }
    }, [])

    const deleteToken = React.useCallback(
        (id) => () => {
            console.log(id)
            let array = []
            array.push(Number(id))
            setOpenDeleteDialog(true)
            // dispatch(deleteTokens(array))
            setDeletedTokens(array)
        },
        []
    );

    const editToken = React.useCallback(
        (row) => () => {
            console.log(row)
            setOpenEditDialog(true)
            setValue('id', row.id)
            setValue('name', row.name)
            // let array = []
            // array.push(Number(id))
            // setDeletedUsers(array)
            // setOpenDeleteDialog(true)
            // dispatch(delUsers(array))
        },
        []
    );

    const showToken = React.useCallback(
        (row) => () => {
            console.log(row)
            setValueShow('public_key', row.public_key)
            setValueShow('private_key', row.private_key)
            setOpenShowDialog(true)
            // let array = []
            // array.push(Number(id))
            // setDeletedUsers(array)
            // setOpenDeleteDialog(true)
            // dispatch(delUsers(array))
        },
        []
    );


    const deleteSelected = () => {
        const array = selectionModel.map((id) => Number(id))
        setDeletedTokens(array)
        // dispatch(deleteTokens(array))
        setOpenDeleteDialog(true)

    };

    const {
        register,
        control,
        handleSubmit,
        setValue
    } = useForm({
        defaultValues: {
            id: null,
            name: '',
        }
    });


    const {
        control: controlShow,
        reset: resetShow,
        setValue: setValueShow,
    } = useForm({
        defaultValues: {
            public_key: '',
            private_key: '',
        }
    });

    const {
        register: registerCreate,
        control: controlCreate,
        handleSubmit: handleSubmitCreate,
        reset: resetCreate,
    } = useForm({
        defaultValues: {
            name: '',
        }
    });


    const onSubmit = data => {
        console.log(data)
        // dispatch(login(data))
        //     .then(unwrapResult)
        //     .then((result) => {
        //         console.log(result); // => 233
        //         // navigate.push('/dashboard')
        //         navigate("/dashboard", {replace: true});
        //     })
        //     .catch((error) => {
        //         console.error(error); // if there is an error
        //     });
    };

    const closeDeleteDialog = (event, reason) => {
        setSelectionModel([])
        setOpenDeleteDialog(false)
    }

    function dialogDeleteTokens() {
        dispatch(deleteTokens(deletedTokens))
        setOpenDeleteDialog(false)
    }

    const closeEditDialog = (event, reason) => {
        setOpenEditDialog(false)
    }

    const onEditToken = data => {
        console.log(data)
        dispatch(edToken(data))
        setOpenEditDialog(false)

    }
    const onCreateToken = data => {
        console.log(data)
        resetCreate()
        dispatch(create(data))
        setOpenCreateDialog(false)
    }

    function generateToken() {
        setOpenCreateDialog(true)
    }


    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Токены
                    </Typography>

                    <div className="row">
                        <div className="col-12 justify-content-end text-end d-flex flex-md-row flex-column">

                            <Button variant="contained" className="mt-md-0 mt-3" onClick={generateToken}>
                                Сгенерировать ключ
                            </Button>

                            <Button variant="contained" className="ms-md-3 mt-md-0 mt-3" onClick={deleteSelected}
                                    disabled={selectionModel.length == 0}>
                                Удалить выбранные
                            </Button>


                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>
                                <DataGridPremium
                                    rows={tokens}
                                    columns={columns}
                                    components={{Toolbar: CustomToolbar}}
                                    componentsProps={{toolbar: {filename: 'Токены'}}}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "id",
                                                    sort: "desc"
                                                }
                                            ]
                                        }
                                    }}
                                    onSelectionModelChange={setSelectionModel}
                                    selectionModel={selectionModel} //
                                    localeText={localeGrid}
                                />
                            </div>
                        </div>
                    </div>

                    <Dialog
                        open={openDeleteDialog}
                        onClose={closeDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Подтвердить действие
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Удалить выбранные токены?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDeleteDialog}>Отмена</Button>
                            <Button onClick={() => dialogDeleteTokens()} autoFocus>
                                Да
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openEditDialog}
                        onClose={closeEditDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Редактировать токен
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <form>
                                        <div>
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({field}) =>
                                                    <TextField
                                                        className="w-100"
                                                        label="Название"
                                                        {...field}
                                                    />}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeEditDialog}>Отмена</Button>
                            <Button onClick={handleSubmit(onEditToken)} autoFocus>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openCreateDialog}
                        onClose={() => setOpenCreateDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Сгенерировать токен
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12 mt-1">
                                    <form>
                                        <div>
                                            <Controller
                                                name="name"
                                                control={controlCreate}
                                                render={({field}) =>
                                                    <TextField
                                                        className="w-100"
                                                        label="Название"
                                                        {...field}
                                                    />}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCreateDialog(false)}>Отмена</Button>
                            <Button onClick={handleSubmitCreate(onCreateToken)} autoFocus>
                                Сгенерировать
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openShowDialog}
                        maxWidth="md"
                        fullWidth={true}
                        onClose={() => setOpenShowDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Ключи API
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12 mt-1">
                                    <form>
                                        <div>
                                            <Controller
                                                name="public_key"
                                                control={controlShow}
                                                render={({field}) =>
                                                    <TextField
                                                        className="w-100"
                                                        label="Публичный ключ"
                                                        {...field}
                                                        // disabled={true}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <ContentPasteIcon sx={{cursor: 'pointer'}}
                                                                                      onClick={() => navigator.clipboard.writeText(field.value)}/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Controller
                                                name="private_key"
                                                control={controlShow}
                                                render={({field}) =>
                                                    <TextField
                                                        className="w-100"
                                                        // disabled={true}
                                                        label="Приватный ключ"
                                                        {...field}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <ContentPasteIcon sx={{cursor: 'pointer'}}
                                                                                      onClick={() => navigator.clipboard.writeText(field.value)}/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenShowDialog(false)}>Закрыть</Button>
                        </DialogActions>
                    </Dialog>

                    {/*<form className="mt-3">*/}
                    {/*    <div>*/}
                    {/*        <Controller*/}
                    {/*            name="address"*/}
                    {/*            control={control}*/}
                    {/*            render={({field}) => <TextField sx={{width: '100%'}} {...field}*/}
                    {/*                                            label="Адрес для вывода"/>}*/}
                    {/*        />*/}
                    {/*    </div>*/}

                    {/*</form>*/}

                    {/*<Button variant="outlined" className="mt-3"*/}
                    {/*        onClick={handleSubmit(onSubmit)}>Сохранить</Button>*/}
                </CardContent>
            </Card>
        </div>
    );
}

export default Tokens;
