import React, {useState} from 'react';
import {Box, Card, CardContent, Grid, MenuItem, Select, Switch, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useGridApiRef} from "@mui/x-data-grid-premium";
import localeGrid from "../components/localeGrid";
import {getStatistics} from "../store/slices/transactionSlice";
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis} from "recharts";
import '../styles/Statistics.css'
import {DateTimeRangePicker} from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import utc from 'dayjs/plugin/utc';
import CustomDataGrid from "../components/CustomDataGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getTokens} from "../store/slices/tokenSlice";
import _ from "lodash";
import {unwrapResult} from "@reduxjs/toolkit";

dayjs.extend(utc);


function Statistics() {
    const [type, setType] = useState(() => {
        const savedType = localStorage.getItem('type-statisticsTable');
        return savedType !== null ? JSON.parse(savedType) : false;
    });
    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = React.useState();
    const [rangeDate, setRangeDate] = React.useState([null, null]);
    const tokensSlice = useSelector((state) => state.token.tokens)
    const [tokens, setTokens] = React.useState([])
    const [selectedToken, setSelectedToken] = React.useState(-1);

    React.useEffect(() => {
        dispatch(getTokens())
    }, [])



    React.useEffect(() => {
        if (tokensSlice.length > 0 ) {
            let copy = _.cloneDeep(tokensSlice)
            copy.unshift({id: -1, name: 'Все ключи'})
            setTokens(copy)
        }
    }, [tokensSlice])

    const changeDate = (data) => {
        console.log('data', data)
        setRangeDate(data)

        if (data[0] != null && data[1] != null) {
            dispatch(getStatistics({
                startDate: data[0],
                endDate: data[1],
                token: selectedToken
            }))
        }
    }




    const handleChangeType = (event) => {
        const newType = event.target.checked;
        setType(newType);
        localStorage.setItem('type-statisticsTable', JSON.stringify(newType));
    };

    const columns = [
        {
            field: 'date',
            headerName: 'Дата',
            width: 150
        },
        {
            field: 'amount',
            type: 'number',
            headerName: "Сумма (RUB)",
            width: 120,
        },
        {
            field: 'amount_usdt',
            type: 'number',

            headerName: "Сумма (USDT)",
            width: 120,
        },
        {
            field: 'transactions',
            type: 'number',

            headerName: "Количество транзакций",
            width: 170,
        },
    ];
    // const [value, setValue] = React.useState([null, null]);

    const isMdUp = useMediaQuery('(min-width: 768px)');

    const [tableHeight, setTableHeight] = React.useState(window.innerHeight - 200);
    const [tableWidth, setTableWidth] = React.useState(window.innerWidth - 50);

    const dispatch = useDispatch();
    const statistics = useSelector((state) => state.transaction.statistics)

    const resize = async () => {
        if (isMdUp) {
            setTableHeight(window.innerHeight - 200);
        } else {
            setTableHeight(window.innerHeight - 250);
        }
        setTableWidth(window.innerWidth - 50)
    }

    React.useEffect(() => {

        const startDate = dayjs().subtract(1, 'week').startOf('day'); // Начало дня (00:00)
        const endDate = dayjs().endOf('day'); // Конец дня (23:59)

        setRangeDate([startDate, endDate]);

        // setRangeDate([dayjs().subtract(1, 'week'), dayjs()])
        dispatch(getStatistics({
            startDate: startDate,
            endDate: endDate,
            token: selectedToken
        }))
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])


    const handleChangeToken = (event) => {
        setSelectedToken(event.target.value);

        dispatch(getStatistics({
            startDate: rangeDate[0],
            endDate: rangeDate[1],
            token: event.target.value
        }))

    };



    const data = [
        {
            date: "23.01.2023",
            amount: 0,
            amount_usdt: 0,
            transactions: 0
        },
        {
            date: "22.01.2023",
            amount: 453,
            amount_usdt: 30,
            transactions: 2
        },
    ];

    const CustomTooltipChart = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <Card sx={{minWidth: 150}}>
                    <CardContent>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            {label}
                        </Typography>

                        <Typography variant="body2">
                            <b>Транзакций: </b> {payload[0].value}
                        </Typography>
                        <Typography variant="body2">
                            <b>Сумма (RUB): </b> {payload[0].payload.amount}
                        </Typography>
                        <Typography variant="body2">
                            <b>Сумма (USDT): </b> {payload[0].payload.amount_usdt}
                        </Typography>
                    </CardContent>
                </Card>
            );
        }

        return null;
    };

    const renderLineChart = (
        <AreaChart
            width={tableWidth}
            height={tableHeight}
            data={statistics}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
            }}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="date"/>
            <YAxis allowDecimals={false}/>
            <Tooltip content={<CustomTooltipChart/>}/>
            <Area
                type="monotone"
                dataKey="transactions"
                stackId="1"
                stroke="#1976d2"
                fill="#1976D280"
            />
        </AreaChart>


    );

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <div className="row">
                        <div
                            className="col-12 justify-content-between d-flex align-items-center  flex-md-row flex-column">
                            <div>
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Таблица</Grid>
                                    <Grid item>
                                        <Switch checked={type} onChange={handleChangeType}/>
                                    </Grid>
                                    <Grid item>График</Grid>
                                </Grid>
                            </div>
                            <div className="d-flex align-items-center mt-xs-0 mt-3">

                                <Typography color="text.primary" className="mx-1">
                                    Период:
                                </Typography>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale='ru'
                                >
                                    <DateTimeRangePicker localeText={{start: 'От', end: 'До'}}
                                                         value={rangeDate}
                                                         ampm={false}
                                        // timeSteps={{ minutes: 60, hours: 2 }}
                                                         adapterLocale={'ru'}
                                                         onChange={(newValue) => changeDate(newValue)}
                                                         renderInput={(startProps, endProps) => (
                                                             <React.Fragment>
                                                                 <TextField {...startProps} size="small"
                                                                            sx={{width: 120}}/>
                                                                 <Box sx={{mx: 2}}> - </Box>
                                                                 <TextField {...endProps} size="small"
                                                                            sx={{width: 120}}/>
                                                             </React.Fragment>
                                                         )}
                                    />

                                </LocalizationProvider>

                                {
                                    tokens.length > 0 &&
                                    (<div className='mx-1 d-flex align-items-center'>

                                        <Typography color="text.primary" className="mx-1">
                                            Api ключ:
                                        </Typography>
                                        <Select
                                            size="small"
                                            value={selectedToken}
                                            labelId="currency-label"
                                            onChange={handleChangeToken}

                                        >
                                            {tokens.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </div>)
                                }

                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>
                                {type ? (
                                    <div>
                                        {renderLineChart}
                                    </div>
                                ) : (

                                    <CustomDataGrid
                                        tableId="statisticsTable" // Уникальный идентификатор для таблицы статистики
                                        columns={columns}
                                        rows={statistics}
                                        rowHeight={40}
                                        getRowId={(row) => row.date}
                                        experimentalFeatures={{aggregation: true}}
                                        componentsProps={{
                                            toolbar: {
                                                filename: 'Статистика',
                                                showQuickFilter: true,
                                            }
                                        }}
                                    />

                                    // <DataGridPremium
                                    //     rowHeight={40}
                                    //     rows={statistics}
                                    //     getRowId={(row) => row.date}
                                    //     columns={columns}
                                    //     experimentalFeatures={{ aggregation: true }}
                                    //     components={{Toolbar: CustomToolbar}}
                                    //     componentsProps={{
                                    //         toolbar: {
                                    //             filename: 'Статистика',
                                    //             showQuickFilter: true,
                                    //         }
                                    //     }}
                                    //     initialState={{
                                    //         aggregation: {
                                    //             model: {
                                    //                 amount: 'sum',
                                    //                 amount_usdt: 'sum',
                                    //                 transactions: 'sum',
                                    //             },
                                    //         },
                                    //         sorting: {
                                    //             sortModel: [
                                    //                 {
                                    //                     field: "created_at",
                                    //                     sort: "desc"
                                    //                 }
                                    //             ]
                                    //         }
                                    //     }}
                                    //     localeText={localeGrid}
                                    // />
                                )}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>

        </div>
    );
}

export default Statistics;
