import React from 'react';
import {Card, CardContent, Chip, Typography} from "@mui/material";
import '../styles/operation.css'; // Tell webpack that Button.js uses these styles
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import localeGrid from "../components/localeGrid";
import {getLogsRefferals} from "../store/slices/logSlice";
import CustomDataGrid from "../components/CustomDataGrid";

function LogsRefferals() {

    function returnStatus(status) {
        switch (status) {
            case 'withdraw':
                return <Chip label="Уменьшение" color="error"/>;
            case 'deposit':
                return <Chip label="Увеличение" color="success"/>;
            default:
                return <Chip label="Неизвестно"/>;
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75
        },
        // {
        //     field: 'user',
        //     headerName: 'Пользователь',
        //     width: 150,
        //     valueGetter: (params) => {
        //         return params.row.user.login
        //     }
        // },
        {
            field: 'refferal',
            headerName: 'Реферал',
            width: 150,
            valueGetter: (params) => {
                return params.row.refferal.login
            }
        },
        {
            field: 'transaction',
            headerName: 'Сумма',
            width: 150,
            valueGetter: (params) => {
                return params.row.transaction?.amount_usdt
            }
        },
        {
            field: 'amount',
            headerName: 'Вознаграждение',
            width: 150,
            // valueGetter: (params) => {
            //     return params.row.value
            // }
        },
        {
            field: 'created_at',
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format('DD.MM.YYYY HH:mm')
            }
        }
    ];


    const [tableHeight, setTableHeight] = React.useState(window.innerHeight - 150);
    const dispatch = useDispatch();
    const logs = useSelector((state) => state.log.logs_refferals)

    const resize = async () => {
        console.log(window.innerHeight)
        setTableHeight(window.innerHeight - 150)
    }

    React.useEffect(() => {
        dispatch(getLogsRefferals())
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    {/*<Typography color="text.primary">*/}
                    {/*    Операции*/}
                    {/*</Typography>*/}


                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>

                                <CustomDataGrid
                                    tableId="referalsTable" // Уникальный идентификатор для таблицы статистики
                                    columns={columns}
                                    rows={logs}
                                    rowHeight={40}
                                    // getRowId={(row) => row.date}
                                    experimentalFeatures={{ aggregation: true }}
                                    componentsProps={{
                                        toolbar: {
                                            filename: 'Рефералы',
                                            showQuickFilter: true,
                                        }
                                    }}
                                />

                                {/*<DataGridPremium*/}
                                {/*    // rowHeight={80}*/}
                                {/*    getRowClassName={(params) => `color-${params.row.status}`}*/}
                                {/*    rows={logs}*/}
                                {/*    columns={columns}*/}
                                {/*    components={{Toolbar: CustomToolbar}}*/}
                                {/*    componentsProps={{*/}
                                {/*        toolbar: {*/}
                                {/*            filename: 'Операции',*/}
                                {/*            showQuickFilter: true,*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    initialState={{*/}
                                {/*        sorting: {*/}
                                {/*            sortModel: [*/}
                                {/*                {*/}
                                {/*                    field: "created_at",*/}
                                {/*                    sort: "desc"*/}
                                {/*                }*/}
                                {/*            ]*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    localeText={localeGrid}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default LogsRefferals;
