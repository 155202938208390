import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  operations: [],
  loading: "IDLE",
  error: null,
};

export const getOperations = createAsyncThunk("operation/get", async (data) => {
  let response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api-operation/get-operations`,
    data
  );
  console.log(response.data);
  return response.data;
});

export const createOperation = createAsyncThunk(
  "operation/create",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-operation/create`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const deleteOperations = createAsyncThunk(
  "operation/delete",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-operation/delete-operations`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const operationSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getOperations.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(createOperation.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(getOperations.fulfilled, (state, action) => {
        state.loading = "idle";
        state.operations = action.payload.data;
        state.error = null;
      })

      .addCase(createOperation.fulfilled, (state, action) => {
        state.loading = "idle";
        state.operations = action.payload.data;
        state.error = null;
      })

      .addCase(deleteOperations.fulfilled, (state, action) => {
        state.loading = "idle";
        state.operations = action.payload.data;
        state.error = null;
      })

      .addCase(getOperations.rejected, (state, action) => {
        state.operations = [];
        state.loading = "failed";
        state.error = action.payload.code;
      })

      .addCase(createOperation.rejected, (state, action) => {
        state.operations = [];
        state.loading = "failed";
        state.error = action.payload.code;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = operationSlice.actions;

export default operationSlice.reducer;
