import React from "react";
import { useDispatch } from "react-redux";
import IPs from "../components/IPs";
import Tokens from "../components/Tokens";
import Withdraw from "../components/Withdraw";
import { testApi } from "../store/slices/ipsSlice";

function Settings() {
  const dispatch = useDispatch();

  function test() {
    dispatch(testApi());
  }

  return (
    <div>
      {/*<div>*/}
      {/*    Settings*/}
      {/*</div>*/}

      {/*<Tokens></Tokens>*/}

      {/*<Button onClick={test}>*/}
      {/*    test*/}
      {/*</Button>*/}
      <div>
        <Withdraw />
      </div>

      <div className="mt-3">
        <IPs />
      </div>

      {/* <div className="mt-3">
                <AutoPayment/>
            </div> */}

      <div className="mt-3">
        <Tokens />
      </div>
    </div>
  );
}

export default Settings;
