import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    logs_refferals: [],
    loading: 'IDLE',
    error: null
}


export const getLogsRefferals = createAsyncThunk(
    'log/get-refferals',
    async (data) => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-transaction/get-logs-refferals`)
        console.log(response.data)
        return response.data
    }
)


export const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLogsRefferals.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.logs_refferals = action.payload.data
                state.error = null;
            })

    }
})

// Action creators are generated for each case reducer function
export const {} = logSlice.actions

export default logSlice.reducer
