import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {getTokens} from "./tokenSlice";

const initialState = {
    ips: [],
    loading: 'IDLE',
    error: null
}


export const editIps = createAsyncThunk(
    'ip/edit',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-ips/edit-ips`, data)
        console.log(response.data)
        return response.data
    }
)

export const getIps = createAsyncThunk(
    'ip/getTokens',
    async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-ips/get-ips`)
        console.log(response.data)
        return response.data
    }
)

export const testApi = createAsyncThunk(
    'ip/getTokens',
    async () => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-test`)
        console.log(response.data)
        return response.data
    }
)



export const ipSlice = createSlice({
    name: 'ip',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(editIps.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(getIps.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(editIps.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.ips = action.payload.data
                state.error = null;
            })

            .addCase(getIps.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.ips = action.payload.data
                state.error = null;
            })


            .addCase(editIps.rejected, (state, action) => {
                state.ips = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })

            .addCase(getIps.rejected, (state, action) => {
                state.ips = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })


    }
})

// Action creators are generated for each case reducer function
export const {} = ipSlice.actions

export default ipSlice.reducer
