import React, {useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox, createFilterOptions,
    Divider, Fade,
    FormControlLabel, IconButton, InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import {editAddress, login} from "../store/slices/authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useDispatch, useSelector} from "react-redux";
import {getTokens} from "../store/slices/tokenSlice";
import {editIps, getIps} from "../store/slices/ipsSlice";
import {setSnackBar} from "../store/slices/uiSlice";

var _ = require('lodash');

const filter = createFilterOptions();


function IPs() {
    const dispatch = useDispatch();
    const [values, setValues] = useState([]);
    const [initialIps, setInitialIps] = useState([]);

    const ips = useSelector((state) => state.ip.ips)


    React.useEffect(() => {
        dispatch(getIps())
            .then(unwrapResult)
            .then((result) => {
                console.log('result', result); // => 233
                // navigate.push('/dashboard')
                setValues(result.data)
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    }, [])

    // React.useEffect(() => {
    //    let copy = _.clone(ips)
    //     setValues(copy)
    // }, [ips])


    const onSubmit = () => {
        const data = values.map(ip => {
            let item = ip;
            if (ip.inputValue) {
                item.ip = ip.inputValue;
                delete item.inputValue;
            }
            return item;
        });

        let deleted_ips = _.differenceBy(ips, data, 'id');

        dispatch(editIps({
            edited_ips: data,
            deleted_ips: deleted_ips
        }))
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                dispatch(setSnackBar({
                    open: true,
                    text: 'IPs успешно сохранены',
                }))
            })
            .catch((error) => {
                console.error(error); // if there is an error
                dispatch(setSnackBar({
                    open: true,
                    text: 'Ошибка сохранения',
                }))
            });
    };


    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Разрешенные IP адреса
                    </Typography>

                    <form className="mt-3">
                        <div>

                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={[]}
                                size="small"
                                freeSolo
                                value={values}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setValues([
                                            ...values,
                                            {
                                                id: -1,
                                                ip: newValue,
                                            }
                                        ]);

                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        // setValue({
                                        //     name: newValue.inputValue,
                                        //     id: 0
                                        // });
                                        setValues([
                                            ...values,
                                            {
                                                id: -1,
                                                ip: newValue.inputValue,
                                            }
                                        ]);
                                    } else {
                                        console.log('newValue', newValue)
                                        setValues(newValue);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    // if (keys.length < 5) {

                                    const {inputValue} = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.ip);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            // ip: inputValue.inputValue,
                                            id: -1,
                                            ip: `Добавить "${inputValue}"`,
                                        });
                                    }
                                    // }
                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.ip;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.ip}</li>}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Разрешенные IP"
                                        placeholder="Введите IP"
                                    />
                                )}
                            />
                        </div>
                    </form>

                    <Button variant="outlined" className="mt-3"
                            onClick={onSubmit}>Сохранить</Button>
                </CardContent>
            </Card>
        </div>
    );
}

export default IPs;
