import {configureStore} from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import tokenReducer from './slices/tokenSlice'
import transactionReducer from './slices/transactionSlice'
import operationReducer from './slices/operationSlice'
import requestReducer from './slices/requestSlice'
import uiReducer from './slices/uiSlice'
import notificationReducer from './slices/notificationSlice'
import ipReducer from './slices/ipsSlice'
import logReducer from './slices/logSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        token: tokenReducer,
        transaction: transactionReducer,
        operation: operationReducer,
        request: requestReducer,
        ui: uiReducer,
        notification: notificationReducer,
        ip: ipReducer,
        log: logReducer
    },
})
