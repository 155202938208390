import PropTypes from 'prop-types';
import {noCase} from 'change-case';
import React, {useMemo, useState} from 'react';
// @mui
import {
    Box,
    List,
    Badge,
    Button,
    Avatar,
    Tooltip,
    Divider,
    Typography,
    IconButton,
    ListItemText,
    ListSubheader,
    ListItemAvatar,
    ListItemButton, Paper,
} from '@mui/material';
// utils
import {fToNow} from '../utils/formatTime';
// _mock_
// components
import Iconify from '../components/Iconify';
import MenuPopover from '../components/MenuPopover';
import {IconButtonAnimate} from '../components/animate';
import {getOperations} from "../store/slices/operationSlice";
import {useDispatch, useSelector} from "react-redux";
import {getNotifications, markNotifications} from "../store/slices/notificationSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import InfoIcon from '@mui/icons-material/Info';
import dayjs from "dayjs";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
    // const [notifications, setNotifications] = useState(_notifications);
    const notifications = useSelector((state) => state.notification.notifications)
    const dispatch = useDispatch();

    // let totalUnRead = notifications.filter((item) => item.is_unread === true).length;

    const [open, setOpen] = useState(null);

    React.useEffect(() => {
        console.log('test')
        dispatch(getNotifications())
    }, [])

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    // function totalUnRead() {
    //     return notifications.filter((item) => item.is_unread == 1).length;
    // }

    const totalUnRead = React.useMemo(() => {
        return notifications.filter((item) => item.is_unread == true).length;
    }, [notifications]);


    const handleMarkAllAsRead = () => {
        let ids = notifications.map(function (obj) {
            if (obj.is_unread == true) {
                return obj.id;
            }
        });
        console.log(ids)

        dispatch(markNotifications(ids))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getNotifications())
            })
            .catch((error) => {

            });
    };


    return (
        <>
            {/*{notifications.length}*/}
            <IconButtonAnimate onClick={handleOpen} sx={{width: 40, height: 40, color: "#FFF"}}>
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="eva:bell-fill" width={20} height={20}/>
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{width: 360, p: 0, mt: '5px', ml: 0.75}}
            >
                <Box sx={{display: 'flex', alignItems: 'center', py: 1, px: 2.5}}>
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="subtitle1">Уведомления</Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                            У вас {totalUnRead} непрочитанных уведомлений
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title="Пометить прочитанными все">
                            <IconButton color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill" width={20} height={20}/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                {notifications.length > 0 &&
                    <span>
                     <Divider sx={{borderStyle: 'dashed'}}/>
                <Paper style={{maxHeight: '80vh', overflow: 'auto'}}>
                    {totalUnRead > 0 &&

                        <List
                            disablePadding
                            subheader={
                                <ListSubheader disableSticky sx={{py: 0.2, px: 2.5, typography: 'overline'}}>
                                    Новые
                                </ListSubheader>
                            }
                        >
                            {notifications.filter((item) => item.is_unread == true).map((notification) => (
                                <NotificationItem key={notification.id} notification={notification}/>
                            ))}
                        </List>
                    }

                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{py: 0.2, px: 2.5, typography: 'overline'}}>
                                Прошлые
                            </ListSubheader>
                        }
                    >
                        {notifications.filter((item) => item.is_unread == false).map((notification) => (
                            <NotificationItem key={notification.id} notification={notification}/>
                        ))}
                    </List>
                </Paper>

                </span>
                }

                {/*<Divider sx={{borderStyle: 'dashed'}}/>*/}
            </MenuPopover>
        </>
    );
}

function NotificationItem({notification}) {
    const title = renderContent(notification);
    const dispatch = useDispatch();

    const handleMarkAsRead = (notification) => {
        // if (notification.is_unread == true) {
        //     dispatch(markNotifications([notification.id]))
        //         .then(unwrapResult)
        //         .then((result) => {
        //             dispatch(getNotifications())
        //         })
        //         .catch((error) => {
        //
        //         });
        // }

    };

    return (
        <Paper
            elevation={0}
            sx={{
                ...(notification.is_unread && {
                    bgcolor: 'action.selected',
                }),
                "&:hover": {
                    //you want this to be the same as the backgroundColor above
                    backgroundColor: "#FFF",
                },
                borderRadius: 0,
                padding: '8px 16px',
                borderBottom: "1px solid #e0e0e0",
            }}
        >
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            alignItems: 'center',
                            color: 'text.disabled',
                        }}
                    >
                        {
                            notification.severity == 'info' &&
                            <div className="d-flex align-items-center">
                                <InfoIcon color="info" fontSize="small" sx={{
                                    marginRight: '5px'
                                }}/>
                                Информация
                            </div>
                        }
                        {
                            notification.severity == 'error' &&
                            <div className="d-flex align-items-center">
                                <InfoIcon color="error" fontSize="small" sx={{
                                    marginRight: '5px'
                                }}/>
                                Ошибка
                            </div>
                        }
                        {
                            notification.severity == 'warning' &&
                            <div className="d-flex align-items-center">
                                <InfoIcon color="warning" fontSize="small" sx={{
                                    marginRight: '5px'
                                }}/>
                                Предупреждение
                            </div>
                        }
                        {
                            notification.severity == 'success' &&
                            <div className="d-flex align-items-center">
                                <InfoIcon color="success" fontSize="small" sx={{
                                    marginRight: '5px'
                                }}/>
                                Успешно
                            </div>
                        }
                        {
                            notification.severity == 'transaction' &&
                            <div className="d-flex align-items-center">
                                <InfoIcon color="success" fontSize="small" sx={{
                                    marginRight: '5px'
                                }}/>
                                Транзакция
                            </div>
                        }
                        <Iconify icon="eva:clock-outline" sx={{mr: 0.5, width: 16, height: 16}}/>
                        {/*{fToNow(notification.created_at)}*/}
                        {dayjs(notification.created_at).format('DD.MM.YYYY HH:mm')}
                    </Typography>

                }
            />
        </Paper>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Box>
            <Typography variant="subtitle2">
                {notification.title}
            </Typography>
            <Typography component="span" variant="body2" sx={{color: 'text.secondary'}}>
                <pre style={{whiteSpace: 'pre-wrap'}}>
                    {notification.description}
                </pre>
            </Typography>
        </Box>
    );
    return title
}
