import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    notifications: [],
    loading: 'IDLE',
    error: null
}

export const getNotifications = createAsyncThunk(
    'notification/get',
    async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-notification/get-notifications`)
        console.log(response.data)
        return response.data
    }
)

export const markNotifications = createAsyncThunk(
    'notification/mark',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-notification/mark-notifications`, data)
        console.log(response.data)
        return response.data
    }
)


export const notificationSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(getNotifications.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.notifications = action.payload.data
                state.error = null;
            })

            .addCase(getNotifications.rejected, (state, action) => {
                state.notifications = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })


    }
})

// Action creators are generated for each case reducer function
export const {} = notificationSlice.actions

export default notificationSlice.reducer
