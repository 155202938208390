import React, {useState} from 'react';
import {
    DataGridPremium,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter,
    useGridApiRef
} from '@mui/x-data-grid-premium';
import dayjs from "dayjs";
import {CircularProgress, LinearProgress} from "@mui/material";
import localeGrid from "./localeGrid";

const CustomDataGrid = ({tableId, columns, rows, onFilterModelChange, ...otherProps}) => {
    const apiRef = useGridApiRef();
    const [initialState, setInitialState] = useState();

    const visibilityJSON = localStorage.getItem(`bonds_visibility-${tableId}`)
    const visibilityModel = (visibilityJSON) ? JSON.parse(visibilityJSON) : {}
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel)
    const filterJSON = localStorage.getItem(`bonds_filter-${tableId}`)
    const initialFilterModel = (filterJSON) ? JSON.parse(filterJSON) : {items: []}
    const [filterModel, setFilterModel] = useState(initialFilterModel)

    const saveSnapshot = React.useCallback(() => {
        console.log('saveSnapshot')
        if (apiRef?.current?.exportState && localStorage) {
            console.log('saveSnapshot 2')
            const currentState = apiRef.current.exportState();
            console.log('currentState', currentState)
            localStorage.setItem(`dataGridState-${tableId}`, JSON.stringify(currentState));

            const stateFromLocalStorage = localStorage?.getItem(`dataGridState-${tableId}`);

            console.log('stateFromLocalStorage', JSON.parse(stateFromLocalStorage))
        }
    }, [apiRef]);

    React.useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem(`dataGridState-${tableId}`);
        if (stateFromLocalStorage) {
            const parsedState = JSON.parse(stateFromLocalStorage);
            // Обновляем только закрепленные колонки, сохраняя остальное состояние
            // parsedState.pinnedColumns = { right: ["actions"] };
            console.log('parsedState', parsedState.aggregation)
            if (parsedState.pinnedColumns.right.length === 0) {
                parsedState.pinnedColumns = {right: ["actions"]};
            }

            if (!parsedState.aggregation && tableId == 'statisticsTable') {
                parsedState.aggregation = {
                    model: {
                        amount: 'sum',
                        amount_usdt: 'sum',
                        transactions: 'sum',
                    },
                }
            }
            setInitialState(parsedState);
        } else {
            console.log('-------------------')
            // Задать начальное состояние, если нет сохраненного состояния
            setInitialState({ pinnedColumns: { right: ["actions"] } });
        }
        // setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});
        // handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            // in case of an SPA remove the event-listener
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot, apiRef]);


    const handleLocalFilterModelChange = (newModel) => {
        localStorage.setItem(`bonds_filter-${tableId}`, JSON.stringify(newModel));
        setFilterModel(newModel);
        if (onFilterModelChange) {
            onFilterModelChange(newModel);
        }
    };

    if (!initialState) {
        return <CircularProgress/>;
    }
    return (
        <>
            <DataGridPremium
                apiRef={apiRef}
                rowHeight={40}
                columns={columns}
                rows={rows}
                localeText={localeGrid}
                initialState={initialState}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                    localStorage.setItem(`bonds_visibility-${tableId}`, JSON.stringify(newModel))
                    setColumnVisibilityModel(newModel)
                }}
                onFilterModelChange={handleLocalFilterModelChange}

                filterModel={filterModel}
                // onFilterModelChange={(newModel) => {
                //     localStorage.setItem(`bonds_filter-${tableId}`, JSON.stringify(newModel))
                //     setFilterModel(newModel)
                // }}
                components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: () => {
                        return (
                            <GridToolbarContainer>
                                <GridToolbarColumnsButton/>
                                <GridToolbarExport csvOptions={{
                                    fileName: `Export_${tableId}_${dayjs().format()}`,
                                    delimiter: ';',
                                    utf8WithBom: true,
                                }}
                                                   printOptions={{disableToolbarButton: true}}
                                />
                                <GridToolbarQuickFilter sx={{
                                    marginLeft: 'auto',
                                }}/>
                            </GridToolbarContainer>
                        )
                    }
                }}
                {...otherProps}
            />
        </>
    );
};

export default CustomDataGrid;
