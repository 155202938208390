import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { authWithDraw, editAutowithdrawal } from "../store/slices/authSlice";
import { setSnackBar } from "../store/slices/uiSlice";

const filter = createFilterOptions();

function Withdraw() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [address, setAddress] = React.useState("");
  const [addressError, setAddressError] = React.useState(false);
  const [amountError, setAmountError] = React.useState(false);
  const [deletedOptions, setDeletedOptions] = React.useState([]);
  const [access, setAccess] = React.useState(false);
  const [autowithdrawal, setAutowithdrawal] = React.useState(0);

  const {
    control: controlAccess,
    handleSubmit: handleSubmitAccess,
    reset: resetAccess,
  } = useForm({
    defaultValues: {
      password: "",
    },
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({
    defaultValues: {
      is_autowithdrawal: false,
      amount_autowithdrawal: 0,
    },
  });

  React.useEffect(() => {
    if (user) {
      let autowithdrawal = JSON.parse(
        user.system_settings.find((item) => item.name === "autowithdrawal")
          .value
      );

      setAutowithdrawal(autowithdrawal);

      if (user.is_autowithdrawal == 1) {
        setValue("is_autowithdrawal", true);
      } else {
        setValue("is_autowithdrawal", false);
      }
      setValue("amount_autowithdrawal", user.amount_autowithdrawal);

      let activeAddress = user.autowithdrawal_addresses.find(
        (address) => address.is_active === 1
      );

      if (activeAddress) {
        console.log("activeAddress", activeAddress);
        setAddress({
          label: activeAddress.address,
          id: activeAddress.id,
        });
      }
    }
  }, [user]);

  React.useEffect(() => {
    if (
      isSubmitted &&
      watch("is_autowithdrawal") &&
      (!address || address.label === "")
    ) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [watch("is_autowithdrawal"), address, isSubmitted]);

  React.useEffect(() => {
    if (
      isSubmitted &&
      watch("is_autowithdrawal") &&
      (watch("amount_autowithdrawal") == "" ||
        Number(watch("amount_autowithdrawal") > autowithdrawal.max_limit) ||
        Number(watch("amount_autowithdrawal") < autowithdrawal.min_limit))
    ) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }, [watch("is_autowithdrawal"), watch("amount_autowithdrawal"), isSubmitted]);

  const onSubmit = (data) => {
    console.log(data);

    if (watch("is_autowithdrawal")) {
      if (!addressError && !amountError) {
        data.address = address;
        data.deletedOptions = deletedOptions;

        dispatch(editAutowithdrawal(data)).finally(() => {
          dispatch(
            setSnackBar({
              open: true,
              text: "Настройки автовывода сохранены",
            })
          );
        });
      } else {
        dispatch(
          setSnackBar({
            open: true,
            text: "Ошибка",
          })
        );
      }
    } else {
      data.deletedOptions = deletedOptions;

      dispatch(editAutowithdrawal(data)).finally(() => {
        dispatch(
          setSnackBar({
            open: true,
            text: "Настройки автовывода сохранены",
          })
        );
      });
    }
  };

  const handleOptionDelete = (optionToDelete) => {
    setDeletedOptions((prev) => [...prev, optionToDelete.id]);
  };

  const options = useMemo(() => {
    if (user.autowithdrawal_addresses) {
      const options = user.autowithdrawal_addresses
        .filter((address) => !deletedOptions.includes(address.id))
        .map((address) => ({
          label: address.address,
          id: address.id,
        }));
      console.log(options);
      return options;
    }
  }, [user.autowithdrawal_addresses, deletedOptions]);

  const onAccess = (data) => {
    console.log(data);
    dispatch(authWithDraw(data))
      .then(unwrapResult)
      .then((result) => {
        dispatch(
          setSnackBar({
            open: true,
            text: "Успешно",
          })
        );
        setAccess(true);
      })
      .catch((error) => {
        dispatch(
          setSnackBar({
            open: true,
            text: "Ошибка",
          })
        );
      });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardContent className="text-start p-3">
          {access ? (
            <>
              <Typography color="text.primary">Настройки автовывода</Typography>

              <form className="mt-3">
                <div>
                  <FormControlLabel
                    control={
                      <Controller
                        name="is_autowithdrawal"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    }
                    label="Включить автовывод"
                  />
                </div>
                <div className="mt-3 row">
                  <div className="col-6">
                    <Autocomplete
                      value={address}
                      onChange={(event, newValue) => {
                        console.log("onChange", newValue);
                        if (typeof newValue === "string") {
                          setAddress({
                            label: newValue,
                            id: "new",
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setAddress({
                            label: newValue.inputValue,
                            id: "new",
                          });
                        } else {
                          console.log("testststs");
                          setAddress(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.label
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            label: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      size="small"
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={options}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option, state) => (
                        <ListItem {...props}>
                          <ListItemText primary={option.label} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              color="error"
                              aria-label="delete"
                              onClick={(e) => handleOptionDelete(option)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Адрес"
                          error={addressError}
                          disabled={!watch("is_autowithdrawal")}
                          helperText={
                            addressError
                              ? "Адрес обязателен для автовывода"
                              : ""
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="col-6">
                    <Controller
                      name="amount_autowithdrawal"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          type="number"
                          sx={{ width: "100%" }}
                          {...field}
                          error={amountError}
                          disabled={!watch("is_autowithdrawal")}
                          helperText={
                            amountError
                              ? `Сумма обязательна для заполнения. Min - $${autowithdrawal.min_limit} Max - $${autowithdrawal.max_limit}`
                              : ""
                          }
                          label="Сумма на балансе для автовывода"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>

              <Button
                variant="outlined"
                className="mt-3"
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
            </>
          ) : (
            <>
              <Typography color="text.primary">Настройки автовывода</Typography>

              <form className="mt-3">
                <div className="">
                  <Controller
                    name="password"
                    control={controlAccess}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        type="password"
                        placeholder="Введите пароль"
                        sx={{ width: "100%" }}
                        {...field}
                      />
                    )}
                  />
                </div>
              </form>

              <Button
                variant="outlined"
                className="mt-3"
                onClick={handleSubmitAccess(onAccess)}
              >
                Разблокировать
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default Withdraw;
