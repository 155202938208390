import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop, Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid-premium";
import {unwrapResult} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    crTransaction,
    edTransaction,
    getTransactions,
    hideTransactions,
    recalculationTransaction,
} from "../store/slices/transactionSlice";
import {setSnackBar} from "../store/slices/uiSlice";
import "../styles/transaction.css";
import {DateTimeRangePicker} from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import CustomDataGrid from "../components/CustomDataGrid";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";

var formData = new FormData();

function Transactions() {

    const [perPage, setPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [page, setPage] = useState(1);
    const [rangeDate, setRangeDate] = React.useState([null, null]);


    const [hidden, setHidden] = useState(() => {
        const savedHidden = localStorage.getItem('hidden-transactionsTable');
        return savedHidden !== null ? JSON.parse(savedHidden) : false;
    });


    function returnLink(params) {
        console.log('rows', JSON.stringify(rows))
        console.log('params.row.merchant.type', params)
        if (
            params.row.merchant.type == "btcn" ||
            params.row.merchant.type == "usm"
        ) {
            return (
                <div className="d-flex align-items-center">
                    <div>{params.row.payment_url}</div>
                    <Button
                        onClick={() => {
                            navigator.clipboard.writeText(params.row.payment_url);
                        }}
                    >
                        <ContentCopyIcon fontSize="small"/>
                    </Button>
                </div>
            );
        }

        if (params.row.merchant.type == "mpc") {
            return (
                <div className="d-flex align-items-center">
                    <a href={params.row.payment_url}>{params.row.payment_url}</a>
                    <Button
                        onClick={() => {
                            navigator.clipboard.writeText(params.row.payment_url);
                        }}
                    >
                        <ContentCopyIcon fontSize="small"/>
                    </Button>
                </div>
            );
        }

    }

    function returnStatus(status) {

        if (!isMdUp) {
            switch (status) {
                case "new":
                    return <Chip icon={<CheckBoxOutlineBlankIcon />}
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
                case "payed":
                    return <Chip icon={<DoneIcon />} color="success"
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
                case "paymentTimeoutNotPayed":
                    return (
                        <Chip
                            icon={<CloseIcon />}
                            sx={{
                                '& .MuiChip-label': {
                                    paddingLeft: "0px"
                                },
                                background: "#f44336",
                            }}
                        />
                    );
                case "On checking":
                    return (
                        <Chip
                            sx={{
                                background: "#ffeb3b",
                                '& .MuiChip-label': {
                                    paddingLeft: "0px"
                                },
                            }}
                            icon={<AccessTimeIcon />}
                        />
                    );
                case "Canceled":
                    return (
                        <Chip
                            icon={<CloseIcon color={"white"} />}
                            sx={{
                                '& .MuiChip-label': {
                                    paddingLeft: "0px"
                                },
                                background: "#f44336",
                                color: "#fff",
                            }}
                        />
                    );
                case "CanceledNotRecalculation":
                    return (
                        <Chip
                            icon={<CloseIcon />}
                            sx={{
                                '& .MuiChip-label': {
                                    paddingLeft: "0px"
                                },
                                background: "#f44336",
                                color: "#fff",
                            }}
                        />
                    );
                case "Finished":
                    return <Chip icon={<DoneIcon />} color="success"
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
                default:
                    return <Chip label="Неизвестно"
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
            }

        } else {
            switch (status) {
                case "new":
                    return <Chip label="Новая"/>;
                case "payed":
                    return <Chip label="Оплачено" color="success"/>;
                case "paymentTimeoutNotPayed":
                    return (
                        <Chip
                            label="Истекло время оплаты"
                            sx={{
                                background: "#f44336",
                            }}
                        />
                    );
                case "On checking":
                    return (
                        <Chip
                            label="На проверке"
                            sx={{
                                background: "#ffeb3b",
                            }}
                        />
                    );
                case "Canceled":
                    return (
                        <Chip
                            label="Закрыта"
                            sx={{
                                background: "#f44336",
                                color: "#fff",
                            }}
                        />
                    );
                case "CanceledNotRecalculation":
                    return (
                        <Chip
                            label="Закрыта"
                            sx={{
                                background: "#f44336",
                                color: "#fff",
                            }}
                        />
                    );
                case "Finished":
                    return <Chip label="Оплачено" color="success"/>;
                default:
                    return <Chip label="Неизвестно"/>;
            }

        }
    }

    function returnArrayActions(params) {
        const {type} = params.row.merchant;
        const {is_hidden, count_recalculation, status} = params.row;

        if (type === "mpc") {
            if (is_hidden) {
                return [
                    <GridActionsCellItem
                        icon={<VisibilityIcon/>}
                        label="Посмотреть информацию о клиенте"
                        onClick={showClientInfo(params.row)}
                    />,
                ];
            }
            return [
                <GridActionsCellItem
                    icon={<VisibilityOffIcon color="error"/>}
                    label="Скрыть"
                    onClick={hideRequisitesTransaction(params.id)}
                />,
                <GridActionsCellItem
                    icon={<VisibilityIcon/>}
                    label="Посмотреть информацию о клиенте"
                    onClick={showClientInfo(params.row)}
                />,
            ];
        }

        if (["btcn", "usm"].includes(type)) {
            // const checkButton = (
            //     <Button
            //         size="small"
            //         variant="outlined"
            //         onClick={sendToCheckTransaction(params.row)}
            //     >
            //         Проверка
            //     </Button>
            // );
            const checkAction = (
                <GridActionsCellItem
                    icon={<ReceiptLongIcon/>}
                    label="Проверка"
                    onClick={sendToCheckTransaction(params.row)}
                />
            );

            const hideAction = (
                <GridActionsCellItem
                    icon={<VisibilityOffIcon color="error"/>}
                    label="Скрыть"
                    onClick={hideRequisitesTransaction(params.id)}
                />
            );

            // if (count_recalculation < 2 && status === "Canceled") {
            //   return is_hidden ? [checkButton] : [checkButton, hideAction];
            // }

            const recalculationCondition = type === 'mco' ? count_recalculation < 1 : count_recalculation < 2;

            if (recalculationCondition && status === "Canceled") {
                return is_hidden ? [checkAction] : [checkAction, hideAction];
            }
            return is_hidden ? [] : [hideAction];
        }

        if (type === "mxp") {
            return is_hidden ? [] : [
                <GridActionsCellItem
                    icon={<VisibilityOffIcon color="error"/>}
                    label="Скрыть"
                    onClick={hideRequisitesTransaction(params.id)}
                />
            ];
        }

        return [];
    }

    const isMdUp = useMediaQuery('(min-width: 768px)');

    const [tableHeight, setTableHeight] = React.useState(window.innerHeight - 300);
    const dispatch = useDispatch();
    const transactions = useSelector((state) => state.transaction.transactions);
    const user = useSelector((state) => state.auth.user);


    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openHideDialog, setOpenHideDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [hiddenTransactions, setHiddenTransactions] = React.useState([]);
    const [openShowDialog, setOpenShowDialog] = React.useState(false);
    const [clienInfo, setClienInfo] = React.useState({});
    const [typeDialog, setTypeDialog] = React.useState("edit");
    const [recalculationDialog, setRecalculationDialog] = React.useState(false);
    // const [recalculation, setRecalculation] = React.useState({});

    const [recalculationFile, setRecalculationFile] = React.useState();
    const [recalculationFile2, setRecalculationFile2] = React.useState();

    const [createDialog, setCreateDialog] = React.useState(false);
    const [paymentLink, setPaymentLink] = React.useState(false);

    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [loading, setLoading] = useState(false); // Состояние для отслеживания загрузки данных

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setDetailPanelExpandedRowIds(newIds);
    }, []);


    const [columns, setColumns] = useState([
        {field: "id", headerName: "ID", width: 75},
        {field: "amount", headerName: "Сумма", width: 100},
        {
            field: "payment_url",
            headerName: "Ссылка/реквизиты",
            width: 250,
            renderCell: (params) => returnLink(params),
        },
        {
            field: "amount_usdt",
            headerName: "Сумма USDT",
            width: 120,
            valueFormatter: (params) => Number(params.value).toFixed(2),
        },
        {
            field: "status",
            headerName: "Статус",
            width: isMdUp ? 120 : 40,
            renderCell: (params) => returnStatus(params.value),
        },
        {field: "comment", headerName: "Комментарий", width: 120},
        {
            field: "paysources_filter",
            headerName: "Тип платежа",
            width: 100,
            renderCell: (params) => {
                if (["0", "BANK_CARD", "ALL", "all"].includes(params.value)) {
                    return <div>Карта</div>;
                } else if (params.value === "sberbank") {
                    return <div>СберБанк</div>;
                } else if (params.value === "tinkoff") {
                    return <div>Тинькофф</div>;
                } else if (params.value === "raiffeisenbank") {
                    return <div>Райффайзен Банк</div>;
                } else {
                    return <div>{params.value}</div>;
                }
            },
        },
        {
            field: "is_api",
            headerName: "API/UI",
            width: 100,
            renderCell: (params) =>
                params.value == 1 ? (
                    <Chip label="API" size="small" color="primary"/>
                ) : (
                    <Chip label="UI" size="small" color="primary"/>
                ),
        },
        {
            field: "token",
            headerName: "API ключ",
            width: 80,
            renderCell: (params) => {
                return <div>{params.value?.name}</div>;
            },
        },
        {
            field: "created_at",
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => dayjs(params.value).format("DD.MM.YYYY HH:mm"),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Действия",
            width: 80,
            getActions: (params) => returnArrayActions(params),
        },
    ]);

    useEffect(() => {
        if (!isMdUp) {
            setColumns((cols) =>
                cols.filter(
                    (col) => col.field !== "comment" && col.field !== "paysources_filter" && col.field !== "is_api" && col.field !== "token" && col.field !== "created_at" && col.field !== "amount_usdt"
                )
            );
        }
    }, [isMdUp]);


    const resize = () => {
        if (isMdUp) {
            setTableHeight(window.innerHeight - 200);
        } else {
            setTableHeight(window.innerHeight - 300);
        }
    };

// Инициализация и первый запрос
    React.useEffect(() => {
        resize();

        const startDate = dayjs().subtract(1, 'week').startOf('day');
        const endDate = dayjs().endOf('day');

        setRangeDate([startDate, endDate]);

        async function fetchPaymentsData() {
            // You can await here
            let response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api-transaction/get-payment-methods`
            );
            setPaymentMethods(response.data.data);
        }

        fetchPaymentsData();


        dispatch(
            getTransactions({
                page: 1, perPage: perPage, searchTerm: searchTerm, hidden: hidden,
                startDate: startDate,
                endDate: endDate
            })
        )
            .then(unwrapResult)
            .then((result) => {
                setRows(result.data.data);
                // setLoading(false)
                console.log('rows', result.data.data);
            })
            .catch((error) => {
                console.error(error);
            });

        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []); // Пустой массив зависимостей, чтобы запустить только при монтировании компонента

// Интервал для обновления данных
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(
                getTransactions({
                    page: page, perPage: perPage, searchTerm: searchTerm, hidden: hidden,
                    startDate: rangeDate[0],
                    endDate: rangeDate[1]
                })
            )
                .then(unwrapResult)
                .then((result) => {
                    setRows(result.data.data);
                    console.log('rows', result.data.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, [perPage, rangeDate, searchTerm, hidden, page]); // Зависимости для обновления интервала


    const hideRequisitesTransaction = React.useCallback(
        (id) => () => {
            console.log(id);
            let array = [];
            array.push(Number(id));
            setOpenHideDialog(true);
            setHiddenTransactions(array);
        },
        []
    );

    const editTransaction = React.useCallback(
        (row) => () => {
            setTypeDialog("edit");
            console.log(row);
            setValue("id", row.id);
            setValue("amount", row.amount);
            setValue("status", row.status);
            setValue("comment", row.comment);
            setValue("paysources_filter", row.paysources_filter);
            setValue("card_number", row.card_number);
            setValue("email", row.email);

            for (const clientInfoJsonKey in row.client_info_json) {
                console.log(clientInfoJsonKey);
                setValueClientInfo(
                    clientInfoJsonKey,
                    row.client_info_json[clientInfoJsonKey]
                );
            }
            setClienInfo(row.client_info_json);

            setOpenEditDialog(true);
        },
        []
    );

    const showClientInfo = React.useCallback(
        (row) => () => {
            console.log(row);

            for (const clientInfoJsonKey in row.client_info_json) {
                console.log(clientInfoJsonKey);
                setValueClientInfo(
                    clientInfoJsonKey,
                    row.client_info_json[clientInfoJsonKey]
                );
            }
            setClienInfo(row.client_info_json);
            setOpenShowDialog(true);

        },
        []
    );
    const sendToCheckTransaction = React.useCallback(
        (row) => async () => {
            console.log(row);
            setValueRecalcuation("order_id", row.uid);
            setValueRecalcuation("id", row.id);
            setValueRecalcuation("merchant", row.merchant.type);
            setValueRecalcuation("count_recalculation", row.count_recalculation);
            setRecalculationDialog(true);
        },
        []
    );

    const deleteSelected = () => {
        const array = selectionModel.map((id) => Number(id));
        setHiddenTransactions(array);
        setOpenHideDialog(true);
    };

    const createTransaction = () => {
        // setTypeDialog('create')
        setPaymentLink(false);

        console.log('paymentMethods[0]', paymentMethods[0])

        setValueCreateTransaction("payment_method", paymentMethods[0].id);

        let defaultValues = {
            client: "",
            email: "",
            recipient: "",
            recipient_system: "",
            sender: "",
            sender_system: "",
            c_from: "",
            c_to: "",
            date: "",
            txn: "",
            ip: "",
            user_agent: "",
            owner: "",
            owner_email: "",
            partner: "",
        };
        setClienInfo(defaultValues);
        setCreateDialog(true);

    };

    const {register, control, handleSubmit, setValue} = useForm({
        defaultValues: {
            id: null,
            amount: "",
            status: "",
            comment: "",
            paysources_filter: "",
            card_number: "",
            email: "",
        },
    });

    const {
        control: controlRecalcuation,
        handleSubmit: handleSubmitRecalcuation,
        setValue: setValueRecalcuation,
        watch: watchRecalcuation,
    } = useForm({
        defaultValues: {
            order_id: 0,
        },
    });

    const {
        register: registerCreateTransaction,
        control: controlCreateTransaction,
        formState: {errors},
        handleSubmit: handleSubmitCreateTransaction,
        setValue: setValueCreateTransaction,
        watch: watchCreateTransaction,
    } = useForm({
        defaultValues: {
            amount: 0,
            payment_method: paymentMethods.length > 0 ? paymentMethods[0].id : null
        },
    });

    // React.useEffect(() => {
    //   if (user.merchants.length > 0) {
    //     setValueCreateTransaction(
    //       "type",
    //       user.merchants.find((merchant) => merchant.active == 1).type
    //     );
    //   }
    // }, [user]);

    const watchMerchantType = watchCreateTransaction("type");

    const {
        register: registerClientInfo,
        control: controlClientInfo,
        setValue: setValueClientInfo,
        getValues: getValuesClientInfo,
    } = useForm({
        defaultValues: {
            client: "",
            email: "",
            recipient: "",
            recipient_system: "",
            sender: "",
            sender_system: "",
            c_from: "",
            c_to: "",
            date: "",
            txn: "",
            ip: "",
            user_agent: "",
            owner: "",
            owner_email: "",
            partner: "",
        },
    });

    const onSubmit = (data) => {
        console.log(data);
    };

    const closeDeleteDialog = (event, reason) => {
        setSelectionModel([]);
        setOpenHideDialog(false);
    };

    function dialogHideTransactions() {
        dispatch(
            hideTransactions({
                array: hiddenTransactions,
            }))
            .then(unwrapResult)
            .then((result) => {
                dispatch(
                    getTransactions({
                        page: page, perPage: perPage, searchTerm: searchTerm, hidden: hidden, startDate: rangeDate[0],
                        endDate: rangeDate[1]
                    })
                )
                    .then(unwrapResult)
                    .then((result) => {
                        console.log(result); // => 233
                        setRows(result.data.data);
                    })
                    .catch((error) => {
                        console.error(error); // if there is an error
                    });
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });


        setOpenHideDialog(false);
    }

    function getMerchParam(type, param) {
        let merch = user.merchants.find((item) => item.type === type);
        return merch[param];
    }

    const closeEditDialog = (event, reason) => {
        setOpenEditDialog(false);
    };

    const onEditTransaction = (data) => {
        data.client_info = JSON.stringify(getValuesClientInfo());

        dispatch(
            edTransaction(data))
            .then(unwrapResult)
            .then((result) => {
                dispatch(
                    getTransactions({
                        page: page, perPage: perPage, searchTerm: searchTerm, hidden: hidden, startDate: rangeDate[0],
                        endDate: rangeDate[1]
                    })
                )
                    .then(unwrapResult)
                    .then((result) => {
                        console.log(result); // => 233
                        setRows(result.data.data);
                    })
                    .catch((error) => {
                        console.error(error); // if there is an error
                    });
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });


        setOpenEditDialog(false);
    };

    const onRecalcuationTransaction = (data) => {
        data.proof1 = recalculationFile;
        data.proof2 = recalculationFile2;
        console.log(data);
        setLoading(true);

        var formData = new FormData();
        formData.append("proof1", recalculationFile);
        formData.append("proof2", recalculationFile2);

        formData.append("order_id", data.order_id);
        formData.append("id", data.id);
        formData.append("fiat_amount", data.fiat_amount);
        console.log(formData);

        dispatch(
            recalculationTransaction(formData))
            .then(unwrapResult)
            .then((result) => {
                dispatch(
                    getTransactions({
                        page: page, perPage: perPage, searchTerm: searchTerm, hidden: hidden, startDate: rangeDate[0],
                        endDate: rangeDate[1]
                    })
                )
                    .then(unwrapResult)
                    .then((result) => {
                        setRows(result.data.data);
                        dispatch(
                            setSnackBar({
                                open: true,
                                text: "Успешно",
                            })
                        );
                        setRecalculationDialog(false);
                        setLoading(false);

                    })
                    .catch((error) => {
                        setRecalculationDialog(false);

                        console.error(error); // if there is an error
                    });
            })
            .catch((error) => {

                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Ошибка",
                    })
                );
                setLoading(false);

                setRecalculationDialog(false);
                console.error(error); // if there is an error
            });
    };


    const onCreateTransaction = (data) => {
        data.clientInfo = getValuesClientInfo();
        data.hidden = hidden;
        setLoading(true);


        dispatch(
            crTransaction(data))
            .then(unwrapResult)
            .then((result) => {

                setPaymentLink(result.data.payment_url);

                setLoading(false);

                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Транзакция успешно создана",
                    })
                );

                dispatch(
                    getTransactions({
                        page: page, perPage: perPage, searchTerm: searchTerm, hidden: hidden, startDate: rangeDate[0],
                        endDate: rangeDate[1]
                    })
                )
                    .then(unwrapResult)
                    .then((result) => {
                        console.log(result); // => 233
                        setRows(result.data.data);
                    })
                    .catch((error) => {
                        console.error(error); // if there is an error
                    });
            })
            .catch((error) => {
                setLoading(false);
                setPaymentLink(false);
                dispatch(
                    setSnackBar({
                        open: true,
                        text: error.message,
                    })
                );
            });
    };

    const onFileChange = (event) => {
        setRecalculationFile(event.target.files[0]);
        console.log("recalculationFile", recalculationFile);
    };

    const onFileChange2 = (event) => {
        setRecalculationFile2(event.target.files[0]);
        console.log("recalculationFile", recalculationFile2);
    };


    function handlePageSizeChange(newPerPage) {
        setPerPage(newPerPage);

        dispatch(
            getTransactions({
                page: page, perPage: newPerPage, searchTerm: searchTerm, hidden: hidden, startDate: rangeDate[0],
                endDate: rangeDate[1]
            })
        )
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    };

    const handleFilterModelChange = (model) => {
        console.log(model.quickFilterValues[0]);
        setSearchTerm(model.quickFilterValues[0]);
        dispatch(
            getTransactions({
                page: page,
                perPage: perPage,
                searchTerm: model.quickFilterValues[0],
                hidden: hidden,
                startDate: rangeDate[0],
                endDate: rangeDate[1]
            })
        )
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    };

    function handlePageChange(page) {
        // const {page} = params

        setPage(page + 1);
        // console.log('test', params)
        dispatch(
            getTransactions({
                page: page + 1, perPage: perPage, searchTerm: searchTerm, hidden: hidden, startDate: rangeDate[0],
                endDate: rangeDate[1]
            })
        )
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    }

    const changeHidden = (checked) => {
        setHidden(checked)
        localStorage.setItem('hidden-transactionsTable', JSON.stringify(checked));
        dispatch(
            getTransactions({
                page: page, perPage: perPage, searchTerm: searchTerm, hidden: checked, startDate: rangeDate[0],
                endDate: rangeDate[1]
            })
        )
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    }


    const changeDate = (data) => {
        console.log('data', data)

        if (data[0] != null && data[1] != null) {
            setRangeDate(data)


            dispatch(
                getTransactions({
                    page: page, perPage: perPage, searchTerm: searchTerm, hidden: hidden,
                    startDate: data[0],
                    endDate: data[1]
                })
            )
                .then(unwrapResult)
                .then((result) => {
                    console.log(result); // => 233
                    setRows(result.data.data);
                })
                .catch((error) => {
                    console.error(error); // if there is an error
                });
        }
    }


    return (
        <div>
            <div>
                <Backdrop
                    sx={{
                        color: "#fff",
                        height: "calc(100% - 68.5px)",
                        marginTop: "auto",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={
                        user.system_settings.find((item) => item.name === "technical_work")
                            .is_activate
                    }
                >
                    {/*<CircularProgress color="inherit"/>*/}
                    <Typography
                        variant="h3"
                        sx={{
                            color: "#fff",
                        }}
                    >
          <pre>
            {
                user.system_settings.find(
                    (item) => item.name === "technical_work"
                ).value
            }
          </pre>
                    </Typography>
                </Backdrop>

                <Card sx={{minWidth: 275}}>
                    <CardContent className="text-start p-3">
                        <div className="row">
                            <div
                                className="col-12 d-flex justify-content-between align-items-center flex-md-row flex-column">
                                <div>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale='ru'
                                    >
                                        <DateTimeRangePicker localeText={{start: 'От', end: 'До'}}
                                                             value={rangeDate}
                                                             ampm={false}
                                                             adapterLocale={'ru'}
                                                             onChange={(newValue) => changeDate(newValue)}

                                        />

                                    </LocalizationProvider>
                                </div>
                                <div className='mt-md-0 mt-3'>
                                    <FormControlLabel control={<Checkbox
                                        checked={hidden}
                                        onChange={(event) => changeHidden(event.target.checked)}

                                        inputProps={{'aria-label': 'controlled'}}
                                    />} label="Показать скрытые"/>


                                    <Button
                                        variant="contained"
                                        className="ms-3"
                                        onClick={deleteSelected}
                                        disabled={selectionModel.length == 0}
                                    >
                                        Скрыть заявки

                                    </Button>

                                    <Button
                                        variant="contained"
                                        className="ms-md-3 mt-md-0 mt-3"
                                        onClick={createTransaction}
                                    >
                                        Создать заявку
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mt-3">
                                <div style={{height: tableHeight, width: "100%"}}>

                                    <CustomDataGrid
                                        rows={rows}
                                        columns={columns}
                                        paginationMode="server"
                                        rowCount={transactions.meta?.total}
                                        tableId="transactionsTable"
                                        pageSize={perPage}
                                        disableColumnFilter={true}
                                        onPageSizeChange={handlePageSizeChange}
                                        rowsPerPageOptions={[10, 50, 100]}
                                        pagination
                                        onPageChange={handlePageChange}
                                        onFilterModelChange={handleFilterModelChange}
                                        getRowClassName={(params) => `color-${params.row.status.replace(/\s+/g, '')}`}
                                        componentsProps={{
                                            toolbar: {
                                                filename: "Транзакции",
                                                showQuickFilter: true,
                                                quickFilterProps: {debounceMs: 100},
                                            },
                                        }}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        onSelectionModelChange={setSelectionModel}
                                        selectionModel={selectionModel}
                                        detailPanelExpandedRowIds={isMdUp ? [] : detailPanelExpandedRowIds}
                                        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                                        getDetailPanelContent={({row}) =>
                                            !isMdUp && <Box sx={{p: 2}}>
                                                <Typography variant={"h6"} color="text.primary">{`Заявка #${row.id}`}</Typography>
                                                <Typography color="text.primary">
                                                    {`API/UI: `}
                                                    {row.is_api == 1 ? (
                                                        <Chip label="API" size="small" color="primary" />
                                                    ) : (
                                                        <Chip label="UI" size="small" color="primary" />
                                                    )}
                                                </Typography>
                                                <Typography color="text.primary">{`Дата создания: ${dayjs(row.created_at).format("DD.MM.YYYY HH:mm")}`}</Typography>
                                                <Typography color="text.primary">{`Сумма USDT: ${row.amount_usdt}`}</Typography>
                                                <Typography color="text.primary">{`Ключ API: ${row.token?.name}`}</Typography>
                                            </Box>
                                        }
                                        getDetailPanelHeight={() => 145}
                                    />
                                </div>
                            </div>
                        </div>

                        <Dialog
                            open={openHideDialog}
                            onClose={closeDeleteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Подтвердить действие
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Скрыть выбранные транзакций?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteDialog}>Отмена</Button>
                                <Button onClick={() => dialogHideTransactions()} autoFocus>
                                    Да
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openEditDialog}
                            maxWidth="sm"
                            fullWidth={true}
                            onClose={closeEditDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Редактировать транзакцию
                            </DialogTitle>
                            <DialogContent>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <form>
                                            <div>
                                                <Controller
                                                    name="amount"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            className="w-100"
                                                            label="Сумма"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            className="w-100"
                                                            label="Статус"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <Controller
                                                    name="comment"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            className="w-100"
                                                            label="Комментарий"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <Controller
                                                    name="paysources_filter"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            className="w-100"
                                                            label="Источник платежа (qw или card)"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <Controller
                                                    name="card_number"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            className="w-100"
                                                            label="Номер карты (последние 4 цифры)"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            className="w-100"
                                                            label="Email"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>Информация о клиенте (HSID)</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {Object.keys(clienInfo).map((keyName, i) => (
                                                            <div className="mt-3" key={keyName}>
                                                                <Controller
                                                                    name={keyName}
                                                                    control={controlClientInfo}
                                                                    render={({field}) => (
                                                                        <TextField
                                                                            className="w-100"
                                                                            label={keyName}
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeEditDialog}>Отмена</Button>
                                <Button onClick={handleSubmit(onEditTransaction)}>
                                    Сохранить
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={createDialog}
                            maxWidth="md"
                            fullWidth={true}
                            onClose={() => setCreateDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Создать заявку
                            </DialogTitle>
                            <DialogContent>
                                {!paymentLink ? (
                                    <div className="row">
                                        <div className="col-12 mt-3">
                                            <form>
                                                <div className="">
                                                    <FormControl style={{minWidth: "100%"}}>
                                                        <InputLabel id="type-label mb-3">
                                                            Способ оплаты
                                                        </InputLabel>
                                                        <Controller name="payment_method"
                                                                    id="payment_method"
                                                                    control={controlCreateTransaction}
                                                                    render={({field}) => (
                                                                        <Select {...field}
                                                                                labelId="payment-method-label"
                                                                                label="Способ оплаты"
                                                                        >
                                                                            {paymentMethods.map((method) => (
                                                                                <MenuItem key={method.id}
                                                                                          value={method.id}>
                                                                                    {method.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div className="mt-3">
                                                    <Controller
                                                        name="amount"
                                                        control={controlCreateTransaction}
                                                        rules={{
                                                            required: "Поле обязательно для заполнения",
                                                            min: {
                                                                value: 300,
                                                                message: `Минимальная сумма платежа 300 рублей`,
                                                            },
                                                            max: {
                                                                value: 300000,
                                                                message: `Максимальная сумма платежа 300000 рублей`,
                                                            },
                                                        }}
                                                        render={({field, fieldState}) => (
                                                            <TextField
                                                                className="w-100"
                                                                type="number"
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error?.message}
                                                                label="Сумма"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <CurrencyRubleIcon/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                            </form>
                                        </div>
                                        {loading ? (
                                            <div className="w-100 mt-3">
                                                <LinearProgress/>
                                            </div>
                                        ) : (
                                            <div className="mt-3 d-flex justify-content-between">
                                                <Button onClick={() => setCreateDialog(false)}>
                                                    Отмена
                                                </Button>
                                                <Button
                                                    onClick={handleSubmitCreateTransaction(
                                                        onCreateTransaction
                                                    )}
                                                >
                                                    Создать
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="">
                                        {loading ? (
                                            <div className="w-100">
                                                <LinearProgress/>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="justify-content-center ">
                                                    <div>Ваша ссылка/реквизиты на оплату:</div>
                                                    {String(paymentLink).startsWith("https") ? (
                                                        <div className="d-flex align-items-center">
                                                            {/*<a*/}
                                                            {/*  href={paymentLink}*/}
                                                            {/*  target="_blank"*/}
                                                            {/*  rel="noopener noreferrer"*/}
                                                            {/*  aria-label="Link"*/}
                                                            {/*>*/}
                                                            {paymentLink}
                                                            {/*</a>*/}
                                                            <Button
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(paymentLink);
                                                                }}
                                                            >
                                                                <ContentCopyIcon fontSize="small"/>
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center">
                                                            {paymentLink}
                                                            <Button
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(paymentLink);
                                                                }}
                                                            >
                                                                <ContentCopyIcon fontSize="small"/>
                                                            </Button>
                                                        </div>
                                                    )}

                                                    <div className="mt-3 d-flex justify-content-end">
                                                        <Button onClick={() => setCreateDialog(false)}>
                                                            Закрыть
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={openShowDialog}
                            maxWidth="md"
                            fullWidth={true}
                            onClose={() => setOpenShowDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Инорфация clientInfo
                            </DialogTitle>
                            <DialogContent>
                                <div className="row">
                                    <div className="col-12">
                                        <form>
                                            {Object.keys(clienInfo).map((keyName, i) => (
                                                <div className="mt-3" key={keyName}>
                                                    <Controller
                                                        name={keyName}
                                                        control={controlClientInfo}
                                                        render={({field}) => (
                                                            <TextField
                                                                className="w-100"
                                                                label={keyName}
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            ))}
                                        </form>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenShowDialog(false)}>Закрыть</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={recalculationDialog}
                            maxWidth="md"
                            fullWidth={true}
                            onClose={() => setRecalculationDialog(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Проверка заявки #{watchRecalcuation("order_id")}
                            </DialogTitle>
                            <DialogContent>
                                <div className="row">
                                    <div className="col-12 mt-1">
                                        <form>
                                            <div>
                                                <Button variant="contained" component="label">
                                                    <input hidden type="file" onChange={onFileChange} accept="image/*"/>
                                                    Загрузить чек #1
                                                </Button>
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    gutterBottom
                                                >
                                                    BMP, JPG, PNG, GIF, WEBP, WEBM размером до 10мб.
                                                </Typography>
                                                <div>{recalculationFile?.name}</div>
                                            </div>

                                            {watchRecalcuation("merchant") != 'mco' &&
                                                <div className="mt-3">
                                                    <Button variant="contained" component="label">
                                                        <input hidden type="file" onChange={onFileChange2}
                                                               accept="image/*"/>
                                                        Загрузить чек #2
                                                    </Button>
                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                    >
                                                        BMP, JPG, PNG, GIF, WEBP, WEBM размером до 10мб.
                                                    </Typography>
                                                    <div>{recalculationFile2?.name}</div>
                                                </div>
                                            }


                                            <div className="mt-3">
                                                <Controller
                                                    name="fiat_amount"
                                                    control={controlRecalcuation}
                                                    rules={{
                                                        min: {
                                                            value: 300,
                                                            message: `Минимальная сумма платежа 300 рублей`,
                                                        },
                                                        max: {
                                                            value: 300000,
                                                            message: `Максимальная сумма платежа 300000 рублей`,
                                                        },
                                                    }}
                                                    render={({field, fieldState}) => (
                                                        <TextField
                                                            className="w-100"
                                                            type="number"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            label="Сумма"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <CurrencyRubleIcon/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </form>

                                        <div>
                                            <Typography
                                                variant="subtitle1"
                                                display="block"
                                                gutterBottom
                                            >
                                                Осталось проверок:{" "}
                                                {2 - watchRecalcuation("count_recalculation")}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {loading ? (
                                    <div className="w-100 mt-3">
                                        <LinearProgress/>
                                    </div>
                                ) : (
                                    <div className="mt-3 d-flex justify-content-between">
                                        <Button onClick={() => setRecalculationDialog(false)}>
                                            Закрыть
                                        </Button>
                                        <Button
                                            disabled={!recalculationFile && !recalculationFile2}  // Добавьте этот атрибут
                                            onClick={handleSubmitRecalcuation(onRecalcuationTransaction)}
                                        >
                                            Отправить
                                        </Button>
                                    </div>
                                )}
                            </DialogActions>

                        </Dialog>

                        {/*<form className="mt-3">*/}
                        {/*    <div>*/}
                        {/*        <Controller*/}
                        {/*            name="address"*/}
                        {/*            control={control}*/}
                        {/*            render={({field}) => <TextField sx={{width: '100%'}} {...field}*/}
                        {/*                                            label="Адрес для вывода"/>}*/}
                        {/*        />*/}
                        {/*    </div>*/}

                        {/*</form>*/}

                        {/*<Button variant="outlined" className="mt-3"*/}
                        {/*        onClick={handleSubmit(onSubmit)}>Сохранить</Button>*/}
                    </CardContent>
                </Card>
            </div>

        </div>
    );
}

export default Transactions;
