import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    tokens: [],
    loading: 'IDLE',
    error: null
}


export const create = createAsyncThunk(
    'token/create',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-token/create`, data)
        console.log(response.data)
        return response.data
    }
)

export const getTokens = createAsyncThunk(
    'token/getTokens',
    async () => {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-token/get-tokens`)
        console.log('response.data', response.data)
        return response.data
    }
)


export const deleteTokens = createAsyncThunk(
    'token/delete',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-token/delete-tokens`, data)
        console.log(response.data)
        return response.data
    }
)

export const edToken = createAsyncThunk(
    'token/edit',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-token/edit-token`, data)
        console.log(response.data)
        return response.data
    }
)



export const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(deleteTokens.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(getTokens.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(edToken.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(create.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.tokens = action.payload.data
                state.error = null;
            })
            .addCase(deleteTokens.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.tokens = action.payload.data
                state.error = null;
            })
            .addCase(getTokens.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.tokens = action.payload.data
                state.error = null;
            })
            .addCase(edToken.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.tokens = action.payload.data
                state.error = null;
            })

            .addCase(create.rejected, (state, action) => {
                state.tokens = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })

            .addCase(deleteTokens.rejected, (state, action) => {
                state.tokens = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })
            .addCase(getTokens.rejected, (state, action) => {
                state.tokens = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })
            .addCase(edToken.rejected, (state, action) => {
                state.tokens = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })


    }
})

// Action creators are generated for each case reducer function
export const {} = tokenSlice.actions

export default tokenSlice.reducer
