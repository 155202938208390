import React from 'react';
import Header from "../layouts/Header";
import ChangePassword from "../components/ChangePassword";
import ChangePasswordWithdraw from "../components/ChangePasswordWithdraw";

function Profile() {
    return (
        <div>
            <ChangePassword/>
            <div className="mt-3">
                <ChangePasswordWithdraw/>
            </div>
        </div>

    );
}

export default Profile;
