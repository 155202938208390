import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Roboto
 */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/**
 * Bootstrap
 */
import 'bootstrap/dist/css/bootstrap.css';

/**
 * MUI
 */
import {createTheme, ThemeProvider} from "@mui/material";
import {ruRU} from '@mui/material/locale';
import {store} from "./store/store";
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";


const theme = createTheme(
    {
        // palette: {
        //     primary: {
        //         main: '#3676F7'
        //     },
        // },
    },
    ruRU,
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <HashRouter>
                <App/>
            </HashRouter>
        </Provider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
