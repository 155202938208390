import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    transactions: [],
    statistics: [],

    loading: 'IDLE',
    error: null
}

export const getTransactions = createAsyncThunk(
    'transaction/getTransactions',
    async (params) => {
        // let response = await axios.get(`${process.env.REACT_APP_API_URL}/api-transaction/get-transactions`)
        let response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api-transaction/get-transactions?page=${params.page}&perPage=${params.perPage}&newPerPage=${params.newPerPage}&searchTerm=${params.searchTerm}&hidden=${params.hidden}&startDate=${params.startDate}&endDate=${params.endDate}`
        );
        console.log(response.data)
        return response.data
    }
)

export const getStatistics = createAsyncThunk(
    'transaction/getStatistics',
    async (data) => {

        const timezoneOffset = -new Date().getTimezoneOffset();


        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-transaction/get-statistics`, {
            ...data,
            timezoneOffset: timezoneOffset
        })
        console.log(response.data)
        return response.data
    }
)


export const hideTransactions = createAsyncThunk(
    'transaction/delete',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-transaction/hide-transactions?hidden=${data.hidden}`, data.array)
        console.log(response.data)
        return response.data
    }
)

export const edTransaction = createAsyncThunk(
    'transaction/edit',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-transaction/edit-transaction`, data)
        console.log(response.data)
        return response.data
    }
)

export const crTransaction = createAsyncThunk(
    'transaction/create',
    async (data, {rejectWithValue}) => {
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-transaction/create-transaction`, data);
            console.log(response.data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue({message: 'Что-то пошло не так'});
            }
        }
    }
);


export const recalculationTransaction = createAsyncThunk(
    'transaction/recalculation',
    async (data) => {
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api-transaction/recalculation-transaction`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        console.log(response.data)
        return response.data
    }
)


export const transactionSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(hideTransactions.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(getTransactions.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(edTransaction.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })
            .addCase(crTransaction.pending, (state) => {
                state.loading = 'loading';
                state.error = null;
            })


            .addCase(hideTransactions.fulfilled, (state, action) => {
                state.loading = 'idle';
                // state.transactions = action.payload.data
                state.error = null;
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.transactions = action.payload.data
                state.error = null;
            })

            .addCase(getStatistics.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.statistics = action.payload.data
                state.error = null;
            })

            .addCase(edTransaction.fulfilled, (state, action) => {
                state.loading = 'idle';
                // state.transactions = action.payload.data
                state.error = null;
            })
            .addCase(crTransaction.fulfilled, (state, action) => {
                state.loading = 'idle';
                // state.transactions = action.payload.transactions
                state.error = null;
            })

            .addCase(recalculationTransaction.fulfilled, (state, action) => {
                state.loading = 'idle';
                // state.transactions = action.payload.data
                state.error = null;
            })


            .addCase(hideTransactions.rejected, (state, action) => {
                // state.transactions = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })
            .addCase(getTransactions.rejected, (state, action) => {
                // state.transactions = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })
            .addCase(edTransaction.rejected, (state, action) => {
                // state.transactions = []
                state.loading = 'failed';
                state.error = action.payload.code;
            })
            .addCase(crTransaction.rejected, (state, action) => {
                // state.transactions = []
                state.loading = 'failed';
                state.error = action.payload ? action.payload.message : action.error.message;
            })


    }
})

// Action creators are generated for each case reducer function
export const {} = transactionSlice.actions

export default transactionSlice.reducer
